import axios from 'axios';
import config from '../config.json'
import { useStorage } from './composables/useStorage';
const url = config.apiAddress;

const getAgentInstance = async () => {
    const { getUser } = useStorage();
    const user = await getUser();
    const instance = axios.create({
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    })
    return instance;
}

export function agent() {

    // Expenses
    const getAll = async (params?: any) => {
        const instance = await getAgentInstance();
        let queryString = "";
        if (params) {
            queryString += "?";
            for (const param of Object.keys(params)) {
                if (params[param]) {
                    queryString += `${param}=${params[param]}&`
                }
            }
            queryString = queryString.slice(0, -1);
        }
        return await instance.get(`${url}/expenses/${queryString}`).catch(err => console.log(err.message));
    }
    const getById = async (id: string) => {
        const instance = await getAgentInstance();
        return await instance.get(`${url}/expenses/${id}`).catch(err => console.log(err.message))
    }
    const getImageUrls = async (uuid: string) => {
        const instance = await getAgentInstance();
        return await instance.get(`${url}/expenses/${uuid}/images`).catch(err => console.log(err.message))
    }
    const getImageUrlsAsAdmin = async (uuid: string) => {
        const instance = await getAgentInstance();
        return await instance.get(`${url}/expenses/${uuid}/images/admin`).catch(err => console.log(err.message))
    }
    const getByUser = async (userId: string) => {
        const instance = await getAgentInstance();
        return await instance.get(`${url}/expenses/user/${userId}`).catch(err => console.log(err.message));
    }
    const getMonthlyByUser = async (userId: string, date: Date) => {
        const instance = await getAgentInstance();
        return await instance.get(`${url}/expenses/user/${userId}${date ? '?date=' + date.toISOString() : ''}`).catch(err => console.log(err.message));
    }
    const create = async (expense: any) => {
        const instance = await getAgentInstance();
        return await instance.post(`${url}/expenses`, expense);
    }
    const update = async (expense: any) => {
        const instance = await getAgentInstance();
        let message, status;
        const res = await instance.put(`${url}/expenses/${expense.publicId}`, expense).catch((err) => {
            message = err.response.data.message;
            status = err.response.status
        })
        if (res) {
            status = res.status;
        }
        if (res && res.data) {
            message = res.data.message;

        }
        return { status, message }
    }

    // Categories
    const getAllCategories = async () => {
        const instance = await getAgentInstance();
        return await instance.get(url + "/expenses/categories");
    }

    // Users
    const getUsers = async () => {
        const instance = await getAgentInstance();
        return await instance.get(url + "/users?archived=false");
    }

    // Auth
    const isBazookaAuthenticated = async (token: string) => {
        const withToken = axios.create({
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return await withToken.get(config.apiAddress + "/auth/isAuthenticated")
    }

    const bazookaLogin = async (email: string, password: string) => {
        return await axios.post(config.apiAddress + "/auth/login", { email: email, password: password }).catch(err => console.log(err));
    }
    return { getAll, getAllCategories, getById, getImageUrls, getImageUrlsAsAdmin, getByUser, getMonthlyByUser, create, update, bazookaLogin, isBazookaAuthenticated, getUsers }
}

